<template>
  <WidthWrapper type="section" class="py-20 md:py-15" background-color="bg-grey-200" :data-aller-id="`strossle${widgetId}`">
    <div class="md:max-w-780 md:mx-auto lg:px-0 lg:max-w-full lg:mx-0">
      <h2 class="font-khand uppercase font-medium text-26 leading-32 text-center md:mx-10 lg:-mx-10 md:text-20 md:leading-26 md:text-left">Sponsoreret indhold</h2>
      <div class="min-h-[700px] md:min-h-[250px] lg:-mx-10" :class="`strossle--${widgetId}`" v-intersect="intersectOptions" />
    </div>
  </WidthWrapper>
</template>

<script setup lang="ts">
import { onDemand } from '@aller/nuxt-base/utils/onDemand';

const props = defineProps({
  widgetId: {
    type: String,
    required: true
  }
});

const loader = new onDemand('https://assets.strossle.com/strossle-widget-sdk/1/strossle-widget-sdk.js');

const loaded = ref(false);

/**
 * Trigger the rendering.
 *
 * @returns void
 */
const renderWidget = (isVisible: boolean): void => {
  if (isVisible && !loaded.value) {
    loader.load(() => {
      //@ts-ignore.
      let strossle = window.strossle || function () {
        (strossle.q = strossle.q || []).push(arguments);
      };
      strossle(props.widgetId, `.strossle--${props.widgetId}`);
      loaded.value = true;
    });
  }
};

const intersectOptions = {
  callback: renderWidget,
  intersection: {
    rootMargin: '250px',
  },
  once: true,
};
</script>
